<template>
  <div class="card">
    <div class="card layout-toppanel">
      <p>Se Gestiona datos básicos de presentación de la institución u oficina</p>
    </div>
      <Toast />
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-lg-6">
        <h5>Representante</h5>
        <InputText
          type="text"
          v-model="product.jefatura"
          placeholder="Nombre del Jefe o Representante"
        ></InputText>
      </div>
      <div class="p-col-12 p-lg-6">
        <h5>E-mail</h5>
        <InputText
          type="text"
          v-model="product.correo"
          placeholder="Correo de contacto de la oficina"
        ></InputText>
      </div>

      <div class="p-col-12 p-lg-6">
        <h5>Teléfono</h5>
        <InputText
          type="text"
          v-model="product.telefono"
          placeholder="Telefono de contacto de la oficina"
        ></InputText>
      </div>

      <div class="p-col-12 p-lg-6">
        <h5>Dirección</h5>
        <InputText
          type="text"
          v-model="product.direccion"
          placeholder="Dirección/ubicación contacto de la oficina"
        ></InputText>
      </div>
      <div class="p-col-12 p-lg-12">
        <h5>Introducción</h5>
        <Textarea
          v-model="product.introduccion"
          required="true"
          rows="5"
          cols="20"
        />
      </div>
      <div class="p-col-12 p-lg-12">
        <h5>¿Quiénes Somos?</h5>
        <Textarea
          v-model="product.quienSoy"
          required="true"
          rows="5"
          cols="20"
        />
      </div>
    </div>

    <div class="p-col-12 p-lg-12">
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-text"
        @click="saveProduct"
      />
    </div>
  </div>
</template>
 
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      submitted: false,
      product: {},
    };
  },

  created() {},
  mounted() {
    this.product = this.model;
    this.getData();
  },
  methods: {
    getData(e) {
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/OFICINA_GET", e === undefined ? true : e)
            .then((res) => {
              this.$swal.close();
              this.product = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              
              if (
                error.response.status == 401 ||
                error.response.status == 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  "Ud. no tiene permitido esta función",
                  "warning"
                );
              }
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },

    saveProduct() {
      let modelo = this.product;

      this.$swal({
        width: 370,
        text: "Cargando...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/OFICINA_UPDATE", modelo)
            .then((res) => {
              this.$swal.close();
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Oficina Actualizado id: " + res.id,
                life: 3000,
              });
            })
            .catch((error) => {
              this.$swal.close();
              if (
                error.response.status == 401 ||
                error.response.status == 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  "Ud. no tiene permitido esta función",
                  "warning"
                );
              }
            });
        },
      }).then((result) => {
        console.log(result);
      });

      this.productDialog = false;
    },
  },
  computed: { ...mapGetters({ model: "gsdialogo/getModelOfic" }) },
};
</script>

